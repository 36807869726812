import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import JSONData from "../../../content/home/community-highlight/history.json"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/aerial-tour.css"


function BlogEntry (data) {


	const getLineFromText = (text, italic, bold, key) => {
    let className=""
    if (italic) {
      className = className+" quote"
    }


    if (bold) {
      return (
        <b key={key}>
          <span className={className}>{text}</span>
          <span className="linebreak"></span>
        </b>
      )
    } else {
      return (
        <span key={key}>
          <span className={className}>{text}</span>
          <span className="linebreak"></span>
        </span>
      )
    }

  } 
  let date = new Date(data.data.date.replace('-','/'));
  const origYear = Math.abs(date.getYear()+1900)

	const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit' }) 
	const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(date ) 
  console.log("year")
  console.log(origYear)

	const dateStr = month+' '+day+', '+origYear
  let text = data.data.entry.map((x, key) => getLineFromText(x.text, x.italic, x.bold, key))

  const photoClassName = data.entryNumber % 2 == 0 ? 'blog-picture blog-picture-left':'blog-picture blog-picture-right' 

  let entryId = ''
  if (data.entryNumber == 0){
  	entryId = 'no-border'
  }


  console.log("data.image: %o",data.data.photoName)
  

	return (
		<div id={entryId} className="blog-entry-wrapper">
		<h4>{dateStr}</h4>
		<div className="blog-picture-wrapper">
		        <div className="text-overlay">
		        <h2>{data.data.photoName.replace('.png','').replace('.jpg', '').replace('.jpeg', '')}</h2>
		      </div>
         <Img
         className={photoClassName}
         fluid={data.image}
             alt=""
          />
		    </div>

    
		<div className="blog-entry-text">
		      <p>
            {text}
            </p> 
		      </div>
		</div>	
	)
}
	


export default BlogEntry 
