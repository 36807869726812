import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import JSONData from "../../../content/home/community-highlight/history.json"
import BlogEntry from "../../components/blog/BlogEntry"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/blog.css"


function Blog () {
	 const query = graphql`
	       query {
	         allFile(
	           sort: { fields: name, order: ASC }
	           filter: { relativePath: { glob: "images/home/community-highlights/old/*.*" } }
	         ) {
	           edges {
	             node {
	               id
	               name
	               childImageSharp {
	                 fluid (maxWidth:750){
	                   ...GatsbyImageSharpFluid
	                 }
	               }
	             }
	           }
	         }
	 	}

	 `
	let data = useStaticQuery(query)
	const images ={}
	data.allFile.edges.forEach(element => images[element.node.name] = element.node.childImageSharp.fluid)
	console.log(images)
	//const photos = data.allFile.edges.map(edge => {edge.node.name: edge.node.childImageSharp.fluid})
	const jsx = JSONData.map((item,key) => <BlogEntry data={item} entryNumber={key} key={key} image={images[item.photoName.replace('.jpg','').replace('.jpeg','').replace('.png','')]} />)


	return(
				<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content  blog">
			<div className="subheader">
					   <h1 className="name"> 
					   St. John's Community Highlights
			       </h1>
			       <h2> 
			        
			       </h2>
			</div>
		<div>{jsx}</div>
		</main>
		<Footer />		
		</div>
		)
}

export default Blog 

			// <div>
			// 	{photos}
			// </div> 